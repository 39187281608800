body {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    /* color: white; */
}

hr {
    background-color: lightgray;
    height: 0.1px;
    margin-left: 0;
    padding: 0;
}

section {
    border-bottom: solid lightgray;
    border-bottom-width: thin;
}

.nav-a:link {
    text-decoration: none;
    color: white;
}

.nav-a:visited {
    text-decoration: none;
    color: white;
}

.nav-a:hover {
    text-decoration: none;
    font-weight: bold;
}

.nav-a:active {
    text-decoration: none;
    color: white;
}

.nav-div {
    background-color: #3b78bd;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
    width: 160px;
    height: 100%;
    text-align: center;
    padding: 0.5rem 1rem;
}

.profile-pic {
    border-radius: 50%;
    height: auto;
    max-width: 75%;
    margin-top: 30px;
}

.project-img {
    width: 100%;
}
.nav-ul {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    list-style: none;
}

.nav-item {
    margin: 5px;
    color: white;
}

.nav-socials {
    position: fixed;
    bottom: 0; 
    margin-left: 27.5px;
    margin-right: 27.5px;
}

.intro-text {
    margin-left: 200px;
    margin-bottom: 25px;
    padding-top: 30px;
    padding-left: 30px;
    color: rgb(16, 34, 70);
}

.experience-text, .projects-text, .education-text, .skills-text {
    margin-top: 25px;
    margin-bottom: 25px;
    margin-left: 200px;
    padding-left: 30px;
}

h1 {
    display: inline;
    font-size: 72px;
}

h2 {
    font-size: 24px;
    margin: 0;
    padding: 0;
    color: rgb(16, 34, 70);
}

h3 {
    font-size: 18px;
    margin: 0;
    padding: 0;
    color:rgb(16, 34, 70);
}

.lastName {
    color: #3b78bd;
}

.resume {
    font-weight: bold;
    text-decoration: underline;
    color: rgb(16, 34, 70);
}

.resume:hover {
    font-weight: bolder;
    text-decoration: underline;
}

.section-header {
    margin-bottom: 25px;
}

.companies, .school-name, .skill-names, .tech-used {
    color: gray;
}

.job-description, .project-description {
    color: gray;
}

.dates {
    color: #3b78bd;
    white-space: nowrap;
}

.card {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
}